<template>
    <div class="last-transactions-wrapper flex-container">
        <div class="wrap-box">
            <div class="last-transactions box-spacing ">
                <span class="button-xs bg-purple" ><img src="@\assets\images\HandCoins.svg" alt="HandCoins"></span>
                <div class="column-box"> 
                    <span class="title-xs">{{protectedType}}</span>
                    <small class="confirmed text-xs" :class="statusClass">{{ protectedStatus }}</small>
                </div>
            </div>
            <div class="box-spacing">
                <div class="coin-wrap">
                    <img src="@\assets\images\Tether (USDT).svg" alt="">
                    <span class="small-badge"><img src="@\assets\images\PolygonVector.svg" alt=""></span>
                </div>
                <span class="title-xs">{{protectedCoin}}</span>
            </div>
        </div>
        <div class="asset-copy" v-if="showExtraContent && !isMobile">
            <span class="text-second">0x23qB0..0451a</span>
            <button class="button-xs">
                <img src="/img/Copy.40c34e8e.svg" alt="Copy">
            </button>
        </div>
        <div class="content-wrapper">
            <div>
                <span class="text-normal">{{protectedAmount}}</span>
                <span class="text-second">{{protectedValue}}</span>
            </div>
            <span  class="text-normal" v-if="!isMobile">{{time}}</span>
            <button class="button-xs" v-if="showExtraButton && !isMobile">
                <img src="@\assets\images\Link.svg" alt="Link">
            </button>
        </div>
       
       
        
    </div>
</template>
<script setup>
import {defineProps,computed} from 'vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';


const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value <= MOBILE_BREAKPOINT);


const props = defineProps({
  protectedType: String,
  protectedStatus: String,
  protectedCoin: String,
  protectedAmount:String,
  protectedValue: String,
  time: String,
  showExtraContent: Boolean,
  showTime:Boolean,
  showExtraButton:Boolean,
})
const statusClass = computed(() => {
  if (props.protectedStatus === "In Progress") {
    return 'progress';
  } else if (props.protectedStatus === "Failure") {
    return 'failure';
  } else if (props.protectedStatus === "Confirmed") {
    return 'confirmed';
  } else {
    return '';
  }
});

</script>
<style scoped>

.last-transactions-wrapper.flex-container{
    padding: 12px;
    border-radius: 5px;
}
.last-transactions-wrapper.flex-container:hover{
    background: var(--dashboard-neutral-50);
}
.confirmed{
 color: var( --dashboard-secondary-600);
}
.progress{
    color: #FFA318;
}
.failure{
    color: #FF5070;
}
.column-box{
    gap:2px;
}

.content-wrapper {
    gap: 20px;
    width: unset;
    text-align: right;
}
.text-normal,.text-second{
    text-align: right;
}

</style>