<template>
  <UserNotifications v-show="bellRef" />
    <div class="wallet" :class="{ 'is-open': isFlexContainerVisible }">
      <div class="wallet-header">
        <div class="column-box">
          <div class="wallet-user-info">
            <img src="@\assets\images\UserFocus.svg" alt="UserFocus" />
            <h2 class="title-m">Account 1</h2>
          </div>
          <div class="box-spacing">
            <span class="text-third">0x23qB0...0451a</span>
            <button class="button-xs">
              <img src="@\assets\images\QrCode.svg" alt="QrCode" />
            </button>
            <button class="button-xs">
              <img src="@\assets\images\Copy.svg" alt="Copy" />
            </button>
          </div>
        </div>
        <div class="widget-container" v-if="isMobile">
          <ColorMode/>
          <LanguageSwitcher/>
          
          <div class="bell-wrap">
            <button class="bell" @click="toggleBell">
              <img src="@\assets\images\Bell.svg" alt="Bell" />
              <div class="badge">5</div>
            </button>
            
          
          </div>
          <button><img src="@\assets\images\User.svg" alt="User" /></button>
        </div>
      </div>
      
      <div class="flex-container wallet-content" v-if="isFlexContainerVisible">
        <div class="wallet-bottom-wrap">
          <div class="wallet-balance-box">
            <div>
              <span class="text">Balance</span>
              <span class="title-m-veriant">$1024,04</span>
            </div>
            <div>
              <span class="text green-text">Protected Balance</span>
              <span class="green-text title-m-veriant">$682,93</span>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="icon-box">
              <div class="asset-icon asset-icon-protected">
                <img src="@\assets\images\Bitcoin (BTC).svg" alt="BTC" />
                <span class="verified">
                  <img src="@/assets/images/Shield.svg" alt="Shield">
                </span>
              </div>
              <div class="asset-icon asset-icon-left">
                <img src="@\assets\images\Ethereum (ETH).svg" alt="ETH" />
              </div>
              <div class="asset-icon asset-icon-protected asset-icon-left">
                <img src="@\assets\images\Tether (USDT).svg" alt="USDT" />
                <span class="verified"
                  ><img src="@/assets/images/Shield.svg" alt="Shield"
                /></span>
              </div>
              <div class="asset-icon asset-icon-left">
                <img src="@\assets\images\USD Coin (USDC).svg" alt="USD" />
              </div>
              <div class="asset-icon asset-icon-left">
                <img src="@\assets\images\Binance Coin (BNB).svg" alt="BNB" />
              </div>
              <div class="asset-icon asset-icon-left">
                <img src="@\assets\images\XRP (XRP).svg" alt="XRP" />
              </div>
            </div>
            <div class="asset-more title-xs">14 Assets</div>
          </div>
        </div>
        <div class="protected-box">
          <img src="@\assets\images\ShieldCheck.svg" alt="ShieldCheck" />
          <span class="green-text text">2 Assets Protected</span>
        </div>
      </div>
      <div class="regtangle-1"></div>
      <div class="regtangle-2"></div>
      <button class="button-small title-xs" @click="toggleFlexContainer">
        {{ isFlexContainerVisible ? '' : 'More Details' }}
        <img src="@\assets\images\CaretUp.svg" alt="CaretUp" v-if="isFlexContainerVisible"/>
        <img src="@\assets\images\CaretDown.svg" alt="CaretUp" v-if="!isFlexContainerVisible"/>
      </button>
    </div>
</template>
<script setup>
import {ref,computed} from 'vue'

import { useScreenWidth } from '@/helpers/useScreenWidth';
import UserNotifications from '@/components/UserNotifications.vue'
import ColorMode from '@/components/ColorMode.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher'

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);
const isFlexContainerVisible = ref(false)
const bellRef= ref(false)

const toggleBell= ()=>{
  bellRef.value= !bellRef.value
}

const toggleFlexContainer = () => {
  isFlexContainerVisible.value = !isFlexContainerVisible.value;
};

</script>
<style scoped>
.wallet {
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  max-height: 125px; 
  height: 100%;
}

.wallet.is-open {
  max-height: 300px; 
  height: 100%;
}

.wallet-user-info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.button-xs {
  background: var(--dashboard-neutral-200);
}
.widget-container > span {
  cursor: pointer;
}
.bell-wrap{
  position: relative;
}

.wallet-balance-box .text {
  font-family: var(--ff-inter);
  font-weight: 500;
  text-align: start;
}
.asset-icon-left {
  margin-left: -12px;
}
.icon-box {
  display: flex;
}
.icon-box > :nth-child(1) {
  z-index: 5;
}
.icon-box > :nth-child(2) {
  z-index: 4;
}
.icon-box > :nth-child(3) {
  z-index: 3;
}
.icon-box > :nth-child(4) {
  z-index: 2;
}
.icon-box > :nth-child(5) {
  z-index: 1;
}
.protected-box .text {
  align-self: unset;
}
.button-small {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  z-index: 10;
  border-radius: 5px 5px 0px 0px;

}
.button-small .title-xs{
    font-size: var(--fs-12);
}
.button-small  img{
    width: 20px;
    height: 20px;
}

</style>