<template>
  <div class="lang-wrapper drop-wrap" @click="toggleLang">
    <button><img src="@\assets\images\Language.svg" alt="Language" /></button>
    <div class="lang-drop drop" v-show="langRef">
    <div v-for="lang in languages" :key="lang.name" class="box-spacing" :class="{ active: activeLanguageRef === lang.name }" @click="setActiveLanguage(lang.name)">
        <div class="box-spacing drop-item">
            <img :src="require(`@/assets/images/${lang.icon}`)" alt="lang.name" />
            <span class="title-xs">{{ lang.label }}</span>
        </div>
        <img src="@\assets\images\Check.svg" alt="Check" v-show="activeLanguageRef === lang.name"/>
    </div>
    </div>

  </div>
</template>
<script setup>
import {ref} from 'vue';
const langRef = ref(false);
const activeLanguageRef = ref('');
const toggleLang=()=>{
    langRef.value=!langRef.value
}
const setActiveLanguage = (lang) => {
  activeLanguageRef.value = lang;
};
const languages = [
  { name: 'United States', label: 'English (US)', icon: 'United States.svg' },
  {name: 'Türkçe', label:'Türkçe', icon:'Turkey.svg'},
  {name: 'English (UK)', label:'English (UK)', icon:'United Kingdom.svg'},
  {name: 'Français', label:'Français', icon:'France.svg'},
  {name: 'Deutsche', label:'Deutsche', icon:'Germany.svg'},
  {name: 'Русский', label:'Русский', icon:'Russia.svg'},
  {name: 'Español', label:'Español', icon:'Spain.svg'},
];
</script>
<style scoped>

.lang-wrapper {
  position: relative;
}

.active{
 background:  #F9FAFB;
}
</style>