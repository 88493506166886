<template>
    <div class="column-box">
        <div class="content-actions">
            <span class="title-s">{{actionName}}</span>
            <div class="box-spacing">
                <div class="input-wrap" v-if="isMobile">
                    <input class="text" type="text" :placeholder="placeholder" />
                    <img
                        src="@\assets\images\MagnifyingGlass.svg"
                        class="input-icon"
                        alt="Magnifying"
                    />
                </div>
                <div class="dropdown text" v-if="showDropdown">
                    {{dropdownText}}
                    <img src="@\assets\images\CaretDown.svg" alt="CaretDown">
                </div>
                <div class="dropdown text" v-if="showExtraDropdown">
                    {{dropdown}}
                    <img src="@\assets\images\CaretDown.svg" alt="CaretDown">
                </div>

            </div>
        
        </div>
        <div class="input-wrap" v-if="!isMobile">
            <input class="text" type="text" :placeholder="placeholder" />
            <img
                src="@\assets\images\MagnifyingGlass.svg"
                class="input-icon"
                alt="Magnifying"
            />
        </div>
    </div>
</template>
<script setup>
import {defineProps,computed} from 'vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);
defineProps({
  actionName: String,
  placeholder: String,
  dropdownText: String,
  dropdown:String,
  showDropdown: Boolean,
  showExtraDropdown:Boolean
})

</script>
<style scoped>
.column-box{
    width: 100%;
    gap: 10px;
}
.content-actions .title-s{
    font-weight: 700;
    white-space: nowrap;
}
.dropdown.text{
    color: var( --dashboard-neutral-500);
}
.dropdown img{
    width: 16px;
    height: 16px;
}
.input-wrap{
    width: 100%;
}
</style>