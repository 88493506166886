import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { AuthState } from "@/types/Account";
import axios from "axios";
import api from "@/services/ApiService";

interface RootState {
  auth: AuthState;
}

const initialState: AuthState = {
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwibmFtZSI6IlZlbWEgU29mdHdhcmUiLCJlbWFpbCI6InRlc3RAdGVzdC5jb20iLCJjcmVhdGVkQXQiOiIyMDI0LTAxLTI4VDAzOjU2OjMwLjg5NVoiLCJpYXQiOjE3MDY0MTU0MzYsImV4cCI6MTczNzk3MzAzNn0.DOMvMna8jXBzC91yzHEE5cgl0ft-RBJhP98RvntdFSE",
  user: null,
};

const mutations: MutationTree<AuthState> = {
  setToken(state, token: string) {
    state.token = token;
  },
  setUser(state, user: any) {
    state.user = user;
  },
  clearToken(state) {
    state.token = null;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, credentials) {
    try {
      const response = await api.post("/auth/login", credentials);
      const token = response.data.token;
      commit("setToken", token);
    } catch (error) {
      console.error("Giriş başarısız:", error);
      throw error;
    }
  },
  async register({ commit }, userData) {
    try {
      const response = await axios.post("/register", userData);
      const token = response.data.token;
      const user = response.data.user;

      commit("setToken", token);
      commit("setUser", user);
    } catch (error) {
      console.error("Kayıt başarısız:", error);
    }
  },
  logout({ commit }) {
    commit("clearToken");
  },
};

// Modül Getters
const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: (state) => !!state.token,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
};

// Modül oluştur
const authModule: Module<AuthState, RootState> = {
  state: initialState,
  mutations,
  actions,
  getters,
};

export default authModule;
