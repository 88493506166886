<template> 
   <MainBrands v-if="isMobile"/>

    <div class="content-actions-wrapper">
        <InteractiveToolBox
            actionName="Last Transection"
            placeholder="Search Transaction"
            :dropdownText="isMobile ? 'Transaction Type' : 'Type'"
            dropdown="Period"
            :showDropdown="true"
            :showExtraDropdown="true"

            />
        <!-- eğer herhangi bir geçmiş yok ise -->
        <!-- <EmptyTransaction/> -->
        <div class="column-box action-wrapper scrollable">
            <div class="content-wrapper">
                <h5 class="transaction-date">Dec 4, 2023</h5>
                <LastActions 
                    protectedType="Protect Payment" 
                    protectedStatus="Confirmed" 
                    protectedCoin="USDT" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                />
                <LastActions 
                    protectedType="Protection Start" 
                    protectedCoin="BTC" 
                    time="14:35" 
                />
                <LastActions 
                    protectedType="Protection Stop" 
                    protectedCoin="MATIC" 
                    time="14:35" 
                />
            </div>
            <div class="content-wrapper">
                <h5 class="transaction-date">Dec 4, 2023</h5>
                <LastActions 
                    protectedType="Receive Token" 
                    protectedStatus="In Progress" 
                    protectedCoin="BTC" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                />
                <LastActions 
                    protectedType="Protection Start" 
                    protectedStatus="Failure" 
                    protectedCoin="MATIC" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                />
            </div>
        </div>
    </div>
    

</template>
<script setup>

// import EmptyTransaction from '@/components/EmptyTransaction.vue';
import LastActions from '@/components/LastActions.vue';
import InteractiveToolBox from '@/components/InteractiveToolBox.vue';
import MainBrands from '@/components/MainBrands.vue';

import { useScreenWidth } from '@/helpers/useScreenWidth';
import {computed} from 'vue'
const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);

</script>
<style scoped>


.content-actions-wrapper .content-wrapper{
    flex-direction: column;
    align-self: stretch;
}
.column-box.action-wrapper{
    gap: 20px;
}
</style>
