import { createRouter, createWebHistory } from "vue-router";

//Layouts
import MainLayoutVue from "@/layouts/MainLayout.vue";

//Modules
import {SettingsRoutes} from '@/router/SettingsRoute';
import {ProtectStepRoutes} from '@/router/ProtectStepRoute';

//Views
import HomeView from "@/views/HomeView.vue";


const routes = [
  {
    path: "/",
    component: MainLayoutVue,
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/protected-assets",
        name: "ProtectedAssets",
        component: () => import("@/views/ProtectedAssetsView.vue")
      },
      {
        path: '/logs',
        name: 'Logs',
        component: () => import('@/views/LogsView.vue'),
      },
     ...SettingsRoutes,
     ...ProtectStepRoutes,

    
    ],
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
