<template>
  <div class="step-inner-content">
    <div class="step-inner box-spacing">
      <h5 class="title-m">Select Your Plan</h5>
      <p class="title-xs">
        Select asset or enter a custom token address as you wish.
      </p>
    </div>
    <div class="select-plan">
      <div class="column-box">
        <div class="select-plan-box wrap-box text-xxs">
          <div class="wrap-box radio-wrap" @click="selectRadio('free')">
            <div
              class="raido"
              :class="{ raido: true, select: selected === 'free' }"
            >
              <span
                :class="{ 'radio-check': true, select: selected === 'free' }"
              ></span>
            </div>
            <span class="radio-text text-xxs">Free</span>
          </div>
          <span class="fee text-xxs">$0</span>
        </div>
        <div class="select-plan-box wrap-box text-xxs">
          <img src="@/assets/images/HourglassLow.svg" alt="HourglassLow" />
          Every 10 second protect
        </div>
        <div class="select-plan-box wrap-box text-xxs">
          <img src="@/assets/images/Lock.svg" alt="Lock" />
          Standart Safe Wallet
        </div>
      </div>
      <div class="column-box second-column">
        <div class="select-plan-box wrap-box text-xxs">
          <div class="wrap-box radio-wrap" @click="selectRadio('paid')">
            <div
              class="raido"
              :class="{ raido: true, select: selected === 'paid' }"
            >
              <span
                :class="{ 'radio-check': true, select: selected === 'paid' }"
              ></span>
            </div>
            <span class="radio-text text-xxs">Paid</span>
          </div>
          <span class="fee text-xxs">$10</span>
        </div>
        <div class="select-plan-box wrap-box text-xxs">
          <img src="@/assets/images/Gauge.svg" alt="Gauge" />
          Every 1 second protect
        </div>
        <div class="select-plan-box wrap-box text-xxs">
          <img src="@/assets/images/LockKeyOpen.svg" alt="LockKeyOpen" />
          Standart Safe Wallet
        </div>
      </div>
    </div>
    <input type="text" class="text" placeholder="Enter Safe Wallet Address" />
    <div class="button-wrapper box-spacing">
      <button class="button-primary" @click="NextStepEvent">
        Next Step: Approval
      </button>
      <button class="button cancel-button text">Cancel</button>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, ref } from "vue";
const emit = defineEmits(["nextStep"]);
const NextStepEvent = () => {
  emit("nextStep");
};
const selected = ref("paid");
function selectRadio(option) {
  selected.value = option;
}
</script>
<style scoped>
.text {
  text-align: start;
}
.title-m {
  color: var(--dashboard-neutral-950);
}
.select-plan {
  display: flex;
  align-items: center;
}
.select-plan-box {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
}
.text-xxs {
  color: var(--dashboard-neutral-500);
  text-align: start;
}
.column-box {
  gap: 0;
}

.column-box {
  background: var(--dashboard-neutral-50);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.second-column {
  background: var(--dashboard-secondary-100);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.second-column .select-plan-box.text-xxs {
  color: var(--dashboard-neutral-950);
}
.raido {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: var(--dashboard-neutral-200);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.radio-check {
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: var(--dashboard-neutral-50);
  box-shadow: 0px 2px 2px 0px rgba(39, 51, 70, 0.12);
}
.raido.select {
  background: linear-gradient(180deg, #9ab8ff 0%, #5069ff 100%);
}
.second-column .radio-wrap .raido.select {
  background: linear-gradient(180deg, #00b7a7 0%, #009186 100%);
}
.radio-check.select {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: linear-gradient(180deg, #f8fafc 0%, #cbd5e1 100%);
  box-shadow: 0px 2px 2px 0px rgba(39, 51, 70, 0.3);
}
.radio-text.text-xxs {
  color: var(--dashboard-neutral-950);
  font-weight: 600;
}
.fee.text-xxs {
  font-family: var(--ff-jetBrains);
  color: var(--dashboard-neutral-950);
}
</style>