<template>
    <div class="asset-box">
            <div class="flex-container">
                <div class="asset ">
                <img src="@\assets\images\Asset Icon.svg" class="" alt="" />
                <div>
                    <span class="title-xs">BTC</span>
                    <div class="asset-copy">
                    <span class="text-second">0x23qB0..0451a</span>
                    <button class="button-xs ">
                        <img src="@\assets\images\Copy.svg" alt="Copy" />
                    </button>
                    </div>
                </div>
                </div>
                <div class="asset-amount">
                <span class="text-normal">0.00004</span>
                <span class="text-second">$12.98</span>
                </div>
            </div>
            <div class="asset-extra">
                <div class="line"></div>
                <div class="column-box">
                    <div class="flex-container">
                        <div class="box-spacing">
                            <img src="@/assets/images/Calendar.svg" alt="Calendar">
                            <span class="text">Protect End</span>
                        </div>
                        <span class="text-normal">03.02.2023 - 14:23</span>
                    </div>
                    <div class="flex-container">
                        <div class="box-spacing">
                            <img src="@/assets/images/Swap.svg" alt="Calendar">
                            <span class="text  green-text">Check Frequency</span>
                        </div>
                        <span class="text-normal green-text">1s</span>
                    </div>
                    <button class="button-primary">
                        <img src="@/assets/images/RocketLaunch.svg" alt="RocketLaunch">
                        Upgrade Premium
                    </button>
                </div>
            </div>
    </div>
</template>
<style scoped>

.text-normal,
.text-second {
  text-align: right;
}
.asset-extra{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    display: none;
    transition: display .3s ease-in-out;
}
.asset-box:hover .asset-extra{
    display: flex;
}
.text{
    color: var(--dashboard-neutral-500);
}
.column-box{
    gap: 10px;
}
.green-text {
  color: var(--dashboard-secondary-600);
}
.button-primary{
    font-size: var(--fs-14);
    gap: 8px;
}
</style>