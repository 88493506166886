<template>
    <TabMenu @update-tab="activeTab = $event"/>
    
        <MainNotification v-if="activeTab === 'notifications'"/>
        <div class="content-actions-wrapper last_transection" v-if="activeTab === 'transactions'">
            <InteractiveToolBox
                actionName="Last Transection"
                placeholder="Search Transaction"
                :dropdownText="isMobile ? 'Type' : 'Transaction Type'"
                dropdown="Period"
                :showDropdown="true"
                :showExtraDropdown="true"
                
            />
            <div class="content-wrapper scrollable">
                <h5 class="transaction-date">Dec 4, 2023</h5>
                <LastActions 
                    protectedType="Send Token" 
                    protectedStatus="Confirmed" 
                    protectedCoin="MATIC" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                    :showExtraButton="true"
                />
                <LastActions 
                    protectedType="Send Token" 
                    protectedStatus="In Progress" 
                    protectedCoin="BTC" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                    :showExtraButton="true"
                />
            </div>
        </div>
        <div class="content-actions-wrapper" v-if="activeTab === 'protections'">
            <InteractiveToolBox
                actionName="Protection History"
                placeholder="Search Asset"
                dropdownText="Start"
                :dropdown="isMobile ? 'Select' : 'Select Network'"
                :showDropdown="true"
                :showExtraDropdown="true"
            />
            <div class="content-wrapper scrollable">
                <h5 class="transaction-date">Dec 4, 2023</h5>
                <LastActions 
                    protectedType="Protection Start" 
                    protectedCoin="BTC" 
                    
                    time="14:35" 
                />
                <LastActions 
                    protectedType="Send Token" 
                    protectedStatus="In Progress" 
                    protectedCoin="BTC" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                    :showExtraContent="true" 
                />
            </div>
        </div>
        <div class="content-actions-wrapper" v-if="activeTab === 'billings'">
            <InteractiveToolBox
                actionName="Billing History"
                placeholder="Search Asset"
                dropdown="Period"
                :showDropdown="false"
                :showExtraDropdown="true"
            />
            <div class="content-wrapper scrollable">
                <h5 class="transaction-date">Dec 4, 2023</h5>
                <LastActions 
                    protectedType="Protect Payment" 
                    protectedStatus="Confirmed" 
                    protectedCoin="USDT" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                />
                <LastActions 
                    protectedType="Protect Payment" 
                    protectedStatus="Confirmed" 
                    protectedCoin="USDT" 
                    protectedAmount="11.987214" 
                    protectedValue="$8.61" 
                    time="14:35" 
                />
            </div>
        </div>
        <div class="content-actions-wrapper"  v-if="activeTab === 'delete'">
            <DeleteAccount/>
        </div>

    <!-- <InteractiveToolBox/> -->
</template>
<script setup>
import TabMenu from '@/components/TabMenu.vue';
import LastActions from '@/components/LastActions.vue'
import  InteractiveToolBox from '@/components/InteractiveToolBox.vue';
// import InteractiveToolBox from '@/components/InteractiveToolBox.vue';
import MainNotification from '@/components/MainNotification.vue';
import DeleteAccount from '@/components/DeleteAccount.vue';
import { ref,computed } from 'vue';
import { useScreenWidth } from "@/helpers/useScreenWidth";

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value <= MOBILE_BREAKPOINT);


const activeTab = ref('notifications');
</script>
<style scoped>
.content-actions-wrapper{
    padding: 0;
}
.content-actions-wrapper .content-wrapper{
        flex-direction: column;
    align-self: stretch;
}
@media (max-width:991px) {
    .content-actions-wrapper{
        padding: 6px 16px 16px 16px;
    }
    
}
</style>