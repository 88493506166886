<template>
  <ul class="dropdown-menu">
    <li class="drop-search">
      <div class="input-wrap">
            <input class="text" type="text"  placeholder="USD" @click.stop/>
            <img src="/img/MagnifyingGlass.a725d7b5.svg" class="input-icon" alt="Magnifying"/>
      </div>
    </li>
    <li v-for="item in items" :key="item.id" class="dropdown-item box-spacing"  @click="selectItem(item.id)">
      <img :src="item.imageSrc" alt="" />
      <span class="text">{{ item.name }}</span>
      <span class="text-normal">{{ item.value }}</span>
      <img v-show="item.id === selectedItemId" src="@/assets/images/Check.svg" alt="Check Icon" />
    </li>
  </ul>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'

defineProps({
  items: Array,
  selectedItemId: Number
});

const emits = defineEmits(['update:selected']);

const selectItem = (id) => {
  emits('update:selected', id);
};
</script>
<style scoped>
.input-wrap {
  border-bottom: 1px solid var(--dashboard-neutral-200);
}
.text {
  color: var(--gray-900);
  flex: 1;
  text-align: start;
}


input {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
}
.input-wrap input:focus {
  border: none;
  box-shadow: none;
}
.drop-search{
    width: 100%;
}
</style>