<template>
<div class="tabs-wrapper">
    <div class="tabs box-layout">
        <span
            class="tab-item text"
            :class="{ active: activeTab === 'notifications' }"
            @click="updateActiveTab('notifications')">
            Notifications
        </span>
        <span
            class="tab-item text"
            :class="{ active: activeTab === 'transactions' }"
            @click="updateActiveTab('transactions')">
            Transactions 
        </span>
        <span
            class="tab-item text"
            :class="{ active: activeTab === 'protections' }"
            @click="updateActiveTab('protections')">
            Protections
        </span>
        <span
            class="tab-item text"
            :class="{ active: activeTab === 'billings' }"
            @click="updateActiveTab('billings')">
            Billings
        </span>
        <span
            class="tab-item text"
            :class="{ active: activeTab === 'delete' }"
            @click="updateActiveTab('delete')">
            Delete
        </span>

        
    </div>
</div>
</template >
<script setup>
import { ref, defineEmits } from 'vue';

const activeTab = ref('notifications');
const emits = defineEmits(['update-tab']);

const updateActiveTab = (tab) => {
  activeTab.value = tab;
  emits('update-tab', tab);
};
</script>

<style scoped>
.tab-item.text{
    font-size: var(--fs-16);
    cursor: pointer;
}
.text.active{
    color: var(--dashboard-neutral-950);
}
.box-layout{
    border-bottom: 1px solid  var(--dashboard-neutral-200);
    min-width: 100%;
   
}

::-webkit-scrollbar{
    display: none;
    -ms-overflow-style: none;  
    scrollbar-width: none; 

}
.tab-item.active{
    border-bottom: 1px solid var(--dashboard-primary-600Main);
}
.tabs-wrapper{
    padding: 16px;
     overflow-x: scroll;
    scrollbar-width: none; 
    width: 100%;
    display: flex;
}
</style>