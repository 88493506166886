<template>
  <div class="step-inner-content">
    <div class="qr" v-show="isMobile"><img src="@\assets\images\qr.png" alt="qr" /></div>
    <div class="step-inner box-spacing">
      <h5 class="title-m">{{isMobile ? 'Scan QR Code' : 'Import Wallet'}}</h5>
      <p class="title-xs">
        We need access to your wallet. After that you can protect your assets.
      </p>
    </div>
    <button class="button-primary">or Import Wallet via Mobile App</button>
    <div class="column-box">
      <p class="text-xxs inner-text">
        We need access to your wallet. After that you can protect your assets.
      </p>
      <div class="show-info wrap-box">
        <img src="@/assets/images/Eye.svg" alt="Eye" />
        <span class="text-xxs">See your private key</span>
      </div>
      <div class="show-info wrap-box">
        <img src="@/assets/images/Eye.svg" alt="Eye" />
        <span class="text-xxs">See your wallet address</span>
      </div>
    </div>
    <div class="button-wrapper box-spacing">
      <button class="button-primary" @click="NextStepEvent">Next Step: Select Asset</button>
      <button class="button cancel-button text">Cancel</button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits,computed } from 'vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);

const emit = defineEmits(['nextStep']);
const NextStepEvent = () => {
  emit('nextStep');
};
</script>
<style scoped>

.step.text-xxs {
  color: var(--dashboard-primary-500);
}
.text-xxs {
  color: var(--dashboard-neutral-500);
}
.title-m {
  color: var(--dashboard-neutral-950);
}

.text-xxs.inner-text {
  text-align: center;
}
@media (max-width:991) {
  .step-inner-content{
    padding: 16px;
  }
}
</style>