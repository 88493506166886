import axios from "axios";
import store from "@/store";

export interface ApiResponse<T> {
  data: T;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

const api = axios.create({
  baseURL: "http://localhost:3000/",
});

api.interceptors.request.use((config) => {
  const token = store.state.auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
