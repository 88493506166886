import { AuthState } from "@/types/Account";
import { createStore, Store } from "vuex";
import authModule from "./Modules/AuthStore";

export interface RootState {
  auth: AuthState;
}

const store: Store<RootState> = createStore<RootState>({
  modules: {
    auth: authModule,
  },
});

export default store;
