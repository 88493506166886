<template>
  <div class="add-token-wrap">
    <button @click="toggleAddToken">
      <img src="@\assets\images\Custom Token.svg" alt="Custom Token" />
    </button>
    <div class="add-token" v-show="AddTokenRef">
        <h5 class="title-s">Add Custom Token</h5>
        <div class="box-spacing ">
            <input
            class="text"
            type="text"
            placeholder="Token Contract Address"
            />
            <input
            class="text"
            type="text"
            placeholder="Token Symbol"
            />
            <input
            class="text"
            type="text"
            placeholder="Token Decimal"
            />
        </div>
        <button class="button">Add</button>
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue';
const AddTokenRef=ref()
const toggleAddToken=()=>{
    AddTokenRef.value=!AddTokenRef.value
}

</script>
<style scoped>
.add-token-wrap{
    position: relative;
}
.add-token {
  display: flex;
  width: 400px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid var(--dashboard-neutral-300);
  background: var(--color-system-neutral-50);
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
   position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(-339px, 29px, 0px);
    z-index: 10;
}
input{
    text-align: start;
}
.title-s{
    font-weight: 700;
}
.box-spacing {
    flex-direction: column;
    width: 100%;
}
input:focus{
    border: 1px solid var(--dashboard-primary-500);
}
.text{
    color: var(--dashboard-neutral-950);
}
.button{
    background: var(--dashboard-primary-600Main);
    color: var(--color-system-neutral-50);
    width: 100%;
    border: 1px solid var(--dashboard-primary-600Main); 
    transition: 300ms ease-in-out;
}
.button:hover{
    border-radius: 5px;
border: 1px solid var(--dashboard-primary-400, #7190FF);
background: var(--dashboard-primary-500, #5069FF);
box-shadow: 0px 0px 0px 4px rgba(51, 64, 250, 0.25);
}
@media (max-width:767px) {
    .add-token{
        min-width: 344px;
        width: 100%;
        transform: translate3d(-259px, 29px, 0px);
    }

}
</style>