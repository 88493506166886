
<template>
 
  <MobileNavigationBar/>
    <div class="container">
      <SideBar/>
      <div  :class="['main-content', routeClass]"  >
        <MainWallet  v-if="showMainWallet"/>
        <router-view/>
      </div>
      <div class="line" v-show="isMobile"></div>
      <div class="last-protections" v-if="isHomeRoute">
          <h3 class="title-m">Last Protections</h3>
          <div class="content-wrapper">
        
            <ProtectionInfo
              protectionAmount="0.0009"
              protectionName="BTC"
              protectionValue="$34.31"
              walletAddress="0x23qB0..0451a"
              network="Polygon"
              protectionStatus="100%"
            />   
            <ProtectionInfo
              protectionAmount="0.0009"
              protectionName="ETH"
              protectionValue="$34.31"
              walletAddress="0x23qB0..0451a"
              network="BNB Smart Chain"
              protectionStatus="100%"
            />   
          
          </div>
      </div>
    
      <MainFooter/>
    </div>
  <MobileNavbar/>

 
</template>
<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import SideBar from '@/components/SideBar.vue';
import MainFooter from '@/components/MainFooter.vue';
import MainWallet from '@/components/MainWallet.vue';
import ProtectionInfo from '@/components/ProtectionInfo.vue';
import MobileNavbar from '@/components/MobileNavbar.vue';
import MobileNavigationBar from '@/components/MobileNavigationBar.vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);



const route = useRoute();


const routeClass = computed(() => {
  return route.path === '/' ? 'home-gap' : 'other-gap';
});
const isHomeRoute = computed(() => route.path === '/'); 
const showMainWallet = computed(() => {
  return isMobile.value && !route.meta.hideMainWallet;
});
</script>


<style >
  @import "@/assets/css/index.css";
 

  
</style>
<style scoped>
.title-m{
    color: var(--dashboard-neutral-950);
    text-align: start;
}
.content-wrapper{
  overflow-x: scroll;
  scrollbar-width: none; 
}
::-webkit-scrollbar{
  display: none;
   -ms-overflow-style: none;  
    scrollbar-width: none; 

}
@media (min-width: 991px) { 
  .home-gap {
    gap: 10px;
  }
  .other-gap {
    gap: 30px;
  }
}
</style>