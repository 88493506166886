<template>
  <div class="step-inner-content">
    <div class="success-status">
      <div class="button step-head">
        <img src="@/assets/images/CheckGreen.svg" alt="Success check" />
      </div>
      <div class="step-inner box-spacing">
        <h5 class="title-m">Protection Successfully Started</h5>
        <p class="title-xs">Your protection has been started.</p>
      </div>
    </div>
    <div class="button-wrapper box-spacing">
      <button class="button-primary">Back to Dashboard</button>
      <button class="button cancel-button text">See My Protected Assets</button>
    </div>
  </div>
</template>
<style scoped>
.button.step-head {
  border-radius: 50%;
  cursor: unset;
  background: var(--dashboard-secondary-50);
}
.title-m {
  color: var(--dashboard-neutral-950);
}
</style>