<template>
  <div class="step-inner-content">
    <div class="button step-head ">
      <img src="@/assets/images/WalletC.svg" alt="Wallet" />
    </div>
    <div class="step-inner box-spacing">
      <h5 class="title-m">Approve and Protect</h5>
      <p class="title-xs">
        Approve requests on your wallet to protect your assets.
      </p>
    </div>
    <div class="column-box">
      <div class="show-info wrap-box">
        <img src="@/assets/images/Polygon (MATIC).svg" alt="Polygon" />
        <span class="text">Polygon Mainnet</span>
      </div>
      <div class="show-info flex-container">
        <div class="wrap-box">
          <img src="@/assets/images/Tether (USDT).svg" alt="USDT" />
          <span class="text">USDT</span>
        </div>
        <span class="text-normal ">141.087123</span>
      </div>
      <div class="show-info wrap-box flex-container">
        <span class="text">Paid Plan</span>
        <span class="text-normal ">$10</span>
      </div>
    </div>
      <div class="button-wrapper box-spacing">
        <button class="button-primary" @click="NextStepEvent">
         Approve on Your Wallet
        </button>
        <button class="button cancel-button text">Back</button>
      </div>
  </div>
</template>
<script setup>
import { defineEmits } from 'vue';
const emit = defineEmits(['nextStep']);
const NextStepEvent = () => {
  emit('nextStep');
};
</script>
<style scoped>
.button.step-head {
  background: var(--dashboard-primary-50);
  border-radius: 50%;
  cursor: unset;
}
.column-box {
  width: 100%;
}
.show-info img {
  width: 20px;
  height: 20px;
}
.text{
    color: var(--gray-900);
}
.title-m {
  color: var(--dashboard-neutral-950);
}
</style>