<template>
     <div class="content-wrapper brands">
        <div class="brand-names-container">
            <div class="box-spacing">
                <img src="@\assets\images\p-logo.svg" alt="Product Hunt Logo">
                <div>
                    <span class="text-xs">FEATURED ON</span>
                    <span class="title-xs">Product Hunt</span>
                </div>
            </div>
            <div class="brand-names-action">
                <img src="@\assets\images\Up chevron.svg" alt="Up chevron">
                <span class="text-normal">148</span>
            </div>
        </div>
        <div class="brand-names-container">
            <div class="box-spacing">
                <img src="@\assets\images\Star.svg" alt="Star">
                <div>
                    <span class="text-xs">EXCELLENT</span>
                    <span class="title-xs">Trustpilot</span>
                </div>
            </div>
            <div>
                <img src="@\assets\images\stars-5.svg" alt="Star">
            </div>
            <div class="text-normal raiting">4.7
                <span class="text-second">/5</span>
            </div>
        </div>

    </div>
</template>
<style scoped>
.brand-names-container .box-spacing{
    gap: 8px;
}
.brand-names-container .text-xs,.brand-names-container .title-xs{
 text-align: start;
}
.brand-names-action{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.brand-names-action img{
    width: 12px;
    height: 12px;
}
.brand-names-container .text-normal{
    font-weight: 700;
}
.raiting{
    display: flex; 
    align-items: center;
}

</style>