<template>
  <div class="drop-wrap">
    <button @click="toggleSort">
      <img src="@\assets\images\Sort by.svg" alt="Sort by" />
    </button>
    <div class="sort-drop drop" v-show="sortRef">
      <div v-for="sortItem in sortItems" :key="sortItem.id" class="box-spacing" :class="{ active: activeSort === sortItem.name }" @click="setActiveSort(sortItem.name)">
        <span class="title-xs">{{ sortItem.name }}</span>
        <img
          src="@\assets\images\Check.svg"
          alt="Check"
          v-show="activeSort === sortItem.name"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue'

const sortRef= ref();
const activeSort=ref()
const setActiveSort = (sort) => {
  activeSort.value = sort;
};
const toggleSort= ()=>{
    sortRef.value=!sortRef.value
}
const sortItems = [
  { id: 1, name: "A-Z Name" },
  { id: 2, name: "Protected" },
  { id: 3, name: "Unprotected" },
  { id: 4, name: "Balance High to Low" },
  { id: 5, name: "Balance Low to High" },
];
</script>
<style scoped>

.drop-wrap .box-spacing{
    justify-content: space-between;
}
.active{
 background:  #F9FAFB;
}
</style>