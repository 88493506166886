<template>
    <div class="column-box">
        <h5 class="title-s">Notification Settings</h5>
        <div class="box-spacing">
            <div class="box" v-for="(notification, index) in notifications" :key="index">
            <div class="column-box">
                <span class="button-xs">
                    <img :src="notification.imgSrc" :alt="notification.altText">
                </span>
                <p class="title-xs">{{ notification.name }}</p>
            </div>
            <ToggleGlobal :isActive="notification.isActive" @update:isActive="value => toggleActive(index, value)"/>
            </div>
        </div>
       
        <div class="line"></div>
        <div class="flex-container"> 
            <h5 class="title-s">Notification Settings</h5>
            <div class="button-small title-xs ">
                <img src="@\assets\images\Checks.svg" alt="Checks">
                Mark all as read
            </div>

        </div>
        <div class="content-wrapper ">
            <div class="notification  column-box">
                <div class="wrap-box">
                    <span class="button">
                        <img src="@\assets\images\ShieldCheckDefault.svg" alt="Shield Check Default">
                    </span>
                    <p class="title-xs">You started protecting USDT</p>
                    <span class="time text-second">27.11.2023</span>
                    <span class="dot"></span>
                </div>
                <div class="line"></div>
            </div>
            <div class="notification  column-box">
                <div class="wrap-box">
                    <span class="button">
                        <img src="@\assets\images\ShieldCheckDefault.svg" alt="Shield Check Default">
                    </span>
                    <p class="title-xs">You started protecting USDT</p>
                    <span class="time text-second">27.11.2023</span>
                    <span class="dot"></span>
                </div>
                <div class="line"></div>
            </div>
            <div class="notification  column-box">
                <div class="wrap-box">
                    <span class="button">
                        <img src="@\assets\images\ShieldCheckDefault.svg" alt="Shield Check Default">
                    </span>
                    <p class="title-xs">You started protecting USDT</p>
                    <span class="time text-second">27.11.2023</span>
                    <span class="dot"></span>
                </div>
                <div class="line"></div>
            </div>
         
          
        </div>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import ToggleGlobal from '@/components/ToggleGlobal.vue'
import DeviceMobileImage from '@/assets/images/DeviceMobile.svg';
import GoogleChromeLogoImage from '@/assets/images/GoogleChromeLogo.svg';
import TelegramBigImage from '@/assets/images/TelegramBig.svg';


 const notifications = ref([
      {
        name: 'Mobile App Notification',
        imgSrc: DeviceMobileImage,
        altText: 'DeviceMobile',
        isActive: false
      },
      {
        name: 'Browser Notification',
        imgSrc: GoogleChromeLogoImage,
        altText: 'Google Chrome Logo',
        isActive: false
      },
      {
        name: 'Telegram Notification',
        imgSrc: TelegramBigImage,
        altText: 'TelegramBig',
        isActive: false
      }
    ]);

    const toggleActive = (index, value) => {
    notifications.value[index].isActive = value;
    };


</script>
<style scoped>
.column-box{
    gap: 20px;
    align-self: stretch;
}
.title-s{
    font-weight: 700;
}
.box .column-box{
    justify-content: center;
    gap: 12px;
    align-self: stretch;
}


.box-spacing{
    width: 100%;
    flex-wrap: wrap;
}
.box .title-xs {
    font-size: var(--fs-12);
}
.wrap-box {
    align-self: stretch;
}
.notification .button{
background: var( --dashboard-secondary-50);
flex: unset;
}
.notification .title-xs{
flex: 1 0 0;
line-height: 21px; 
}
.text-second{
    color: var(--dashboard-neutral-950);
}
.content-wrapper{
    flex-direction: column;
    height: 340px;
    overflow-y:scroll ;
}
.notification.column-box{
    width: 100%;
    gap: 10px;
    padding-right: 12px;
}
@media (max-width:991px) {
    .column-box{
        padding: 6px 16px 16px 16px;
    }
    
}
</style>