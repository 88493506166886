<template>
<h5 class="title-s ">Delete Account</h5>
  <p class="title-xs">
    Water's like me. It's laaazy... Boy, it always looks for the easiest way to
    do things If you hypnotize it, it will go away. You can spend all day
    playing with mountains. Those great big fluffy clouds. Son of a gun. Let's
    have a little bit of fun today.
  </p>
  <button class="button text">Delete Account</button>
</template>
<style scoped>
.title-xs{
    font-weight: 500;
    line-height: 21px;
}
.title-s{
    font-weight: 700;
}
.button{
    background-color: #FF5070;
    color: #fff;
    align-self: flex-start;
}
.text{
    font-size: var(--fs-16);
}
</style>