import ProtectStepView from "@/views/ProtectStep/ProtectStepView.vue";

export const ProtectStepRoutes = [
  {
    path: "/protectstep",
    name: "ProtectStep",
    component: ProtectStepView,
    meta: { hideMainWallet: true },
  },
 
];
