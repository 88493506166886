<template>
    <div>
    <div class="color-wrap drop-wrap" @click="toggleMode">
            <button><img src="@\assets\images\Color Mode.svg" alt="Color Mode"/></button>
            <div class="color-drop drop" v-show="modeRef">
              <div class="box-spacing" :class="{ active: activeColorMode === 'Light Mode' }" @click="setActiveColorMode('Light Mode')" >
                <div class="box-spacing drop-item">
                  <img src="@\assets\images\SunDim.svg" alt="Sun">
                  <span class="title-xs">Light Mode</span>
                </div>
                <img src="@\assets\images\Check.svg" alt="Check" v-show="activeColorMode === 'Light Mode'">
              </div>
              <div class="box-spacing" :class="{ active: activeColorMode === 'Dark Mode' }" @click="setActiveColorMode('Dark Mode')" >
                <div class="box-spacing drop-item">
                  <img src="@\assets\images\Color Mode.svg" alt="Color Mode">
                  <span class="title-xs">Dark Mode</span>
                </div>
                <img src="@\assets\images\Check.svg" alt="Check" v-show="activeColorMode === 'Dark Mode'">
              </div>
            </div>
          </div>
        
    </div>
</template>
<script setup>
import {ref} from 'vue'
const modeRef= ref(false)
const activeColorMode= ref()
const toggleMode= ()=>{
  modeRef.value=!modeRef.value
}
const setActiveColorMode = (mode) => {
  activeColorMode.value = mode;
};
</script>
<style scoped>


.color-wrap{
    position: relative;
}


.active{
 background:  #F9FAFB;
}
</style>