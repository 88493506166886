<template>
  <div class="step-wrap">
    <div class="content-wrapper">
      <div class="step-name">
        <div class="wrap-box">
          <button class="button go-back"  @click="goToPreviousStep">
            <img src="@\assets\images\CaretLeft.svg" alt="" />
          </button>
        <div class="flex-container">
          <div class="column-box step-box">
             <span class="step text-xxs">Step {{ currentStep }}: {{ stepDescription }}</span>
             <h6 class="title-s">{{ stepCompletedText }}</h6>
          </div>
          <span class="text-xxs next-step" v-if="currentStep < 5">Next Step: {{ nextStepText }}</span>
        </div>
        </div>
      </div>
      <div class="step-indicator wrap-box">
        <div class="progress" :class="{ completed: currentStep === 1 || currentStep > 1 }"></div>
        <div class="ellipse" :class="{ completed: currentStep === 1 || currentStep > 1 }"> 
          <span class="ellipse-inner " :class="{ completed: currentStep === 1 || currentStep > 1 }"></span>
        </div>
        <div class="progress" :class="{ completed: currentStep  === 2 || currentStep >2  }"></div>
        <div class="ellipse" :class="{ completed: currentStep  === 2 || currentStep >2  }">
          <span class="ellipse-inner" :class="{ completed: currentStep  === 2 || currentStep >2  }"></span>
        </div>
        <div class="progress" :class="{ completed: currentStep === 3 || currentStep >3 }"></div>
        <div class="ellipse" :class="{ completed: currentStep  === 3 || currentStep >3  }">
          <span class="ellipse-inner" :class="{ completed: currentStep === 3 || currentStep >3 }"></span>
        </div>
        <div class="progress" :class="{ completed: currentStep === 4 || currentStep >4 }"></div>
        <div class="ellipse" :class="{ completed: currentStep  === 4 || currentStep >4  }">
          <!-- Eğer başarısız olursa failed classı bind et -->
          <span class="ellipse-inner" :class="{ completed: currentStep === 4 || currentStep >4 }"></span>
        </div>
        <!-- Eğer başarısız olursa failed classı bind et -->
        <div class="progress" :class="{ completed: currentStep === 5 || currentStep >5 }"></div>
      </div>
    </div>
    <div class="line" v-show="!isMobile" ></div>
    <div class="step-inner-content-box column-box">
        <StartProtect   v-if="currentStep === 1"
        @nextStep="goToNextStep"/>
        <SelectAssets  v-if="currentStep === 2"  @nextStep="goToNextStep"/>
        <SelectPlan v-if="currentStep === 3" @nextStep="goToNextStep" />
        <ApproveWallet v-if="currentStep === 4" @nextStep="goToNextStep" />
        <ProtectionSuccessfull  v-if="currentStep === 5"/>
        <!-- eğer başarısız olursa ProtectionUnsuccessful componenti görünecek -->
        <ProtectionUnsuccessful  v-if="currentStep === 5"/>
    </div>
  </div>
</template>
<script setup>
import StartProtect from '@/components/StartProtect'
import SelectAssets from '@/components/SelectAsset.vue';
import SelectPlan from '@/components/SelectPlan.vue';
import ApproveWallet from '@/components/ApproveWallet.vue';
import ProtectionSuccessfull from '@/components/ProtectionSuccessfull.vue';
// import ProtectionUnsuccessful from '@/components/ProtectionUnsuccessful.vue';
import { ref,computed } from 'vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value >= MOBILE_BREAKPOINT);

const currentStep = ref(1);

const stepCompletedText = computed(() => `${currentStep.value - 1} to 4 Completed`);


const stepDescription = computed(() => {
  switch (currentStep.value) {
    case 1: return "Connect and Authorize Wallet";
    case 2: return "Select Asset";
    case 3: return "Select Your Plan";
    case 4: return "Approval";
    case 5: return "Success"; 
    default: return "";
  }
});

const nextStepText = computed(() => {
  switch (currentStep.value) {
    case 1: return "Select Asset";
    case 2: return "Select Your Plan";
    case 3: return "Approval";
    case 4: return "Results"; 
    default: return "";
  }
});

const goToNextStep = () => {
  if(currentStep.value < 5) {
    currentStep.value += 1; 
  }
};
const goToPreviousStep = () => {
  if(currentStep.value > 1) {
    currentStep.value -= 1;
  }
};
</script>
<style scoped>

.step-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 0px 0px 5px 5px;
  background: var(--dashboard-neutral-50);
  width: 100%;
  gap: 30px;
}
.content-wrapper {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.button.go-back {
  background: var(--dashboard-neutral-100);
}
.flex-container {
  align-items: flex-start;
}
.step-name,.step-name .flex-container
{
  width: 100%;
}
.step.text-xxs {
  color: var(--dashboard-primary-500);
}
.text-xxs {
  color: var(--dashboard-neutral-500);
}
.column-box.step-box {
  gap: 0;
}
.step-indicator.wrap-box {
  gap: 8px;
  align-self: stretch;
}
.progress {
  height: 4px;
  flex: 1 0 0;
  border-radius: 5px;
  background: var(--dashboard-neutral-200);
}
.ellipse-inner {
  width: 8px;
  height: 8px;
  background: var(--dashboard-neutral-400);
  border-radius: 50%;
}

.ellipse {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--dashboard-neutral-200);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ellipse.completed{
  background: var(--dashboard-primary-100);
}
.completed,.ellipse-inner.completed {
  background: var(--dashboard-primary-500);
}
.failed,.ellipse-inner.failed{
    background: #FF5070;
}

.step-inner-content-box.column-box {
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
}
@media (max-width:991px) {
  .content-wrapper,.step-inner-content{
    padding: 16px;
    
  }
  .step-wrap{
    background: transparent;
    gap: 0;
  }
  .step-name .flex-container{
    display: block;
  }

}







</style>