<template>
<div class="column-box  mobile-menu" v-if="isMobile">
   <div class="flex-container">
        <img src="@\assets\images\logo.svg" alt="Logo" />
        <div class="box-spacing">
            <button class="button-xs" v-show="!isOpenRef">
                <img src="@\assets\images\User.svg" alt="User">
            </button>
            <button class="bell button-xs" v-show="!isOpenRef">
                <img src="/img/Bell.d3453f59.svg" alt="Bell">
                <div class="badge">5</div>
            </button>
            <button class="button-xs" @click="menuToggle">
                <img src="@\assets\images\List.svg" alt="List" v-show="!isOpenRef">
                <img src="@\assets\images\X.svg" alt="X" v-show="isOpenRef">
            </button>
        </div>
   </div>
   <div class="column-box menu-drop" v-if="isOpenRef">
    <div class="line"></div>
    <div class="nav-content box-spacing">
        <button  class="wallet-button"> 0x23q..0451a 
            <img  src="/img/CaretDown.3b0f6866.svg" alt="CaretDown">
        </button>
        <div class="flex-container button-wrap">
            <button class="button">
                <img src="@\assets\images\Color Mode.svg" alt="Color Mode">
            </button>
            <button class="button">
                <img src="@\assets\images\Language.svg" alt="Language">
            </button>
        </div>
    </div>
    <div class="line"></div>
      <MainBrands/>
   </div>
</div>
</template>
<script setup>
import { computed,ref } from 'vue';
import { useScreenWidth } from '@/helpers/useScreenWidth';
import MainBrands from '@/components/MainBrands'

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value <= MOBILE_BREAKPOINT);

const isOpenRef= ref(false)
const menuToggle=()=>{
    isOpenRef.value= !isOpenRef.value
}
</script>
<style scoped>
.column-box{
    gap: 16px;
    width: 100%;
}
.wallet-button{
    width: 100%;
    justify-content: space-between;
}
.nav-content.box-spacing{
    width: 100%;
    flex-direction: column;
}
.button{
    background: var(--dashboard-neutral-50)
}
.flex-container.button-wrap{
    gap: 5px;
}
.mobile-menu{
    padding: 16px 24px;
    background-color: var(--color-system-neutral-50);
    z-index: 9999;
    position: relative;
}
.button-xs{
    padding: 8px;
}
.menu-drop{
        position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(0px, 60px, 0px);
    padding:  16px 24px;
    border-bottom: 1px solid #F7F7F7;
    background: var(--color-system-neutral-50);
    box-shadow: 0px 50px 25px -25px rgba(0, 0, 0, 0.10);
    }
</style>