<template>
    <div class="step-inner-content">
        <div class="step-inner box-spacing">
            <h5 class="title-m">Select Your Asset</h5>
            <p class="title-xs">
                Select asset or enter a custom token address as you wish.
            </p>
        </div>
        <div class="column-box">
            <div class="drop-wrap"  @click="toggleNetworkDropdown">
               <div class="dropdown text" :class="{ 'active': networkDropdown }">
                    <span>{{ selectedNetworkName || "Select Network" }}</span>
                    <img src="@\assets\images\CaretDown.svg" :class="{ 'rotate-180': networkDropdown }" alt="CaretDown">
                </div>
                <AssetDropdown v-if="networkDropdown" :items=networkOptions :selectedItemId="selectedNetworkId" @update:selected="handleSelectNetwork" />
            </div>
            <div class="drop-wrap" @click="toggleAssetsDropdown">
                <div class="dropdown text" :class="{ 'active': assetsDropdown }">
                    <span>{{selectedAssetOptions || "Select Assets"}}</span>
                    <div class="wrap-box ">
                        <span class="text-normal">0.00</span>
                        <img src="@\assets\images\CaretDown.svg" :class="{ 'rotate-180': assetsDropdown }" alt="CaretDown">
                    </div>
                </div>
                <AssetDropdown v-if="assetsDropdown" :items="assetOptions" :selectedItemId="selectedAssetId" @update:selected="handleSelectAsset" />

            </div>
           <input type="text" class="text " placeholder="Name your protection">
        </div>
        <div class="line-wrap">
            <span class="line-s"></span>
            <span class="text">OR</span>
            <span class="line-s"></span>
        </div>
        <div class="drop-wrap" @click="toggleCutomTokenDropdown">
            <div class="dropdown text">
                <span>Enter Custom Token</span>
                <img src="@\assets\images\CaretDown.svg" :class="{ 'rotate-180': cutomTokenDropdown }"  alt="CaretDown" >
            </div>
            <CustomTokenDropdown v-if="cutomTokenDropdown"/>
        </div>
        <div class="button-wrapper box-spacing">
            <button class="button-primary" @click="NextStepEvent">Next Step: Select Plan</button>
            <button class="button cancel-button text">Cancel</button>
        </div>
    </div>
</template>
<script setup>
import { defineEmits,ref } from 'vue';
import AssetDropdown from '@/components/AssetDropdown';
import CustomTokenDropdown from '@/components/CustomTokenDropdown';

const emit = defineEmits(['nextStep']);
const NextStepEvent = () => {
  emit('nextStep');
};

const networkDropdown = ref(false);
const assetsDropdown = ref(false);
const cutomTokenDropdown = ref(false);

const toggleNetworkDropdown = () => {
    networkDropdown.value = !networkDropdown.value;
    if(assetsDropdown.value) assetsDropdown.value = false; 
}

const toggleAssetsDropdown = () => {
    assetsDropdown.value = !assetsDropdown.value;
    if(networkDropdown.value) networkDropdown.value = false; 
}
const toggleCutomTokenDropdown = () => {
    cutomTokenDropdown.value = !cutomTokenDropdown.value;
    
}
const networkOptions = [
    { id: 1, name: 'Ethereum', imageSrc: require('@/assets/images/Ethereum (ETH).svg') },
    { id: 2, name: 'Bitcoin', imageSrc: require('@/assets/images/Bitcoin (BTC).svg') },
];
const assetOptions=[
    { id: 1, name: 'Ethereum', imageSrc: require('@/assets/images/Ethereum (ETH).svg'),value:'141.087123' },
    { id: 2, name: 'Ethereum', imageSrc: require('@/assets/images/Ethereum (ETH).svg'),value:'141.087123' },

]

const selectedNetworkName = ref("");
const selectedNetworkId = ref(null);  

  
const handleSelectNetwork = (id) => {
    selectedNetworkId.value = id;

  const selectedNetwork = networkOptions.find(network => network.id === id);
  if (selectedNetwork) {
    selectedNetworkName.value = selectedNetwork.name; 
  }
};
const selectedAssetOptions = ref("");
const selectedAssetId = ref(null);  
const handleSelectAsset = (id) => {
  selectedAssetId.value = id;
  const selectedAsset = assetOptions.find(asset => asset.id === id);
  if (selectedAsset) {
    selectedAssetOptions.value = selectedAsset.name;  
  }
};
</script>
<style scoped>
.title-m {
  color: var(--dashboard-neutral-950);
}
.column-box{
    width: 100%;
}
.dropdown span{
    align-self: stretch;
    flex: 1 0 0 ;
}
.text{
    text-align: start;
}
.drop-wrap{
    width: 100%;
}
.dropdown img{
    width: 16px;
    height: 16px;
}
.text-normal{
    color: var(--dashboard-neutral-500);
    line-height: 20px;
}
.line-wrap{
    display: flex;
    align-items: center;
    width: 100%;
}
.line-s{
    background: var(--dashboard-neutral-200);
    height: 1px;
    flex: 1;
}
.line-wrap .text{
    color: var(--dashboard-neutral-500);
    padding: 0px 10px
}
.active{
    border: 1px solid var(--dashboard-primary-300);
    box-shadow: 0px 0px 0px 2px rgba(51, 64, 250, 0.25);
}
input.text{
  color: var(--dashboard-neutral-950);
}
</style>