/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


// Create Vue App
const app = createApp(App);

// Use Plugins
app.use(store);
app.use(router);




// Mount the app
app.mount("#app");
