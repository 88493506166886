<template>
     <div class="drop-wrap">
          <button class="wallet-button" @click="toggleSelect">
            0x23q..0451a
            <img src="@\assets\images\CaretDown.svg" alt="CaretDown" />
          </button>
          <ul class="drop" v-if="selectRef">
            <li class="box-spacing">
              <span class="text-third">0x23qB0...0451a</span>
                <button class="button-xs"><img src="/img/Copy.40c34e8e.svg" alt="Copy"></button>
                <button class="button-xs"><img src="/img/QrCode.24a1e86b.svg" alt="QrCode"></button>
            </li>
            <li class="box-spacing">
              <span class="title-xs">View on Explorer</span>
                <button class="button-xs"><img src="@\assets\images\ListMagnifyingGlass.svg" alt="ListMagnifyingGlass"></button>
            </li>
            <li class="box-spacing">
              <span class="title-xs disconnect">Disconnect</span>
                <button class="button-xs plugs"><img src="@\assets\images\Plugs.svg" alt="Plugs"></button>
            </li>
          </ul>
        </div>
</template>
<script setup>
import {ref} from 'vue';

const selectRef= ref(false)
const toggleSelect= ()=>{
    selectRef.value= !selectRef.value
}
</script>
<style scoped>
.text-third{
  color: var(--dashboard-neutral-950)
}

.drop .box-spacing{
  justify-content: space-between;
}
.plugs{
  background: rgba(255, 80, 112, 0.20);
}
.drop{
    position: absolute;
    margin: 0px;
    z-index: 10;
    right: 0;
    margin-top: 10px;
}
.title-xs.disconnect{
    color: #FF5070;
}
</style>