<template>
  <nav class="content-wrapper mobile-nav" v-if="isMobile">
    <router-link
      to="/"
      class="box-spacing text-xs"
      :class="{ active: activeButton === 'HouseSimple' }"
      @click="setActive('HouseSimple')"
    >
     <img src="@/assets/images/HouseSimple.svg" alt="" v-if="activeButton === 'HouseSimple'" />
          <img src="@/assets/images/HouseSimpleD.svg" alt=""  v-else/>
      <span>Home</span>
    </router-link>
    <button
      class="box-spacing text-xs"
      :class="{ active: activeButton === 'Shield' }"
      @click="setActive('Shield')"
    >
        <img src="@/assets/images/ShieldFill.svg" alt="" v-if="activeButton === 'Shield'" />
          <img src="@/assets/images/Shield.svg" alt="" v-else />
      <span>Protect</span>
    </button>
    <button
      class="box-spacing text-xs"
      :class="{ active: activeButton === 'Notepad' }"
      @click="setActive('Notepad')"
    >
        <img src="@/assets/images/NotepadFill.svg" alt="" v-if="activeButton === 'Notepad'" />
        <img src="@/assets/images/Notepad.svg" alt="" v-else />
      <span>Logs</span>
    </button>
    <router-link
      to="/settings"
      class="box-spacing text-xs"
      :class="{ active: activeButton === 'Gear' }"
      @click="setActive('Gear')"
    >
        <img src="@/assets/images/GearFill.svg" alt="" v-if=" activeButton === 'Gear'" />
        <img src="@/assets/images/Gear.svg" alt="" v-else/>
      <span>Settings</span>
    </router-link>
    <div class="nav-line"></div>
  </nav>
</template>
<script setup>
import { computed, ref } from "vue";
import { useScreenWidth } from "@/helpers/useScreenWidth";

const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value <= MOBILE_BREAKPOINT);

const activeButton = ref("HouseSimple");

const setActive = (buttonName) => {
  activeButton.value = buttonName;
};
</script>
<style scoped>
.mobile-nav {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  background-color: var(--color-system-neutral-50);
  display: flex;
  width: 100%;
  padding: 10px 12px 28px 12px;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--dashboard-neutral-200);
}
.box-spacing{
    flex-direction: column;
    gap: 4px;
}
.text-xs{
    color: var(--dashboard-neutral-500);
    width: 100%;
    line-height: normal;
    letter-spacing: unset;
}
.active{
    color: var(--dashboard-neutral-950);
}
.nav-line{
    width: 135px;
  height: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  border-radius: 100px;
  opacity: 0.1;
  background: var(--dashboard-neutral-500);
}
img{
  width: 24px;
  height: 24px;
}
</style>>
    
