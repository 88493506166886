<template>
  <div class="toggle" :class="{active: isActive}" @click="toggle">
    <div class="ball"></div>
  </div>
</template>


<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  isActive: Boolean
});

const emit = defineEmits(['update:isActive']);

const toggle = () => {
  emit('update:isActive', !props.isActive);
};
</script>