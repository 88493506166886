<template>
  <div class="notification-wrap">
    <div class="flex-container">
        <div class="box-spacing">
            <h5 class="title-m">Notifications</h5>
            <span class="badge-m text-second ">5</span>
        </div>
        <div class="button-small title-xs">
            <img src="@\assets\images\Checks.svg" alt="Checks"> 
            Mark all as read
         </div>

    </div>
    <div class="notifications scrollable">
        <div class="notification  column-box">
            <div class="wrap-box">
                <span class="button new">
                    <img src="@\assets\images\ShieldCheckDefault.svg" alt="Shield Check Default">
                </span>
                <p class="title-xs">You started protecting USDT</p>
                <span class="time text-second">27.11.2023</span>
                <span class="dot"></span>
            </div>
            <div class="line"></div>
        </div>        
    </div>
    <button class="button see-all title-xs ">See All Notifications</button>
  </div>
</template>
<style scoped>
.notification-wrap {
  display: flex;
  width: 400px;
  height: 400px;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid var(--dashboard-neutral-300);
  background: var(--color-system-neutral-50);
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  margin-top: 60px;
  z-index: 5;
}
.title-m{
    color: var(--dashboard-neutral-950);
}
.scrollable{
    height: 214px;
    padding-right: 12px;
}
.button.see-all{
    background: var(--dashboard-neutral-950);
    color:  var(--color-system-neutral-50);
    width: 100%;
}
.new{
    background: var(--dashboard-secondary-50);
}
</style>