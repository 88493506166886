<template>
  <ul class="dropdown-menu"  @click.stop>
    <li class="dropdown-item search" v-if="!showItems">
      <input type="text" placeholder="Token Contract Address"  @click.stop/>
      <button class="button-primary" @click="checkClicked">Check</button>
    </li>
    <div class="column-box" v-if="showItems">
    <li class="dropdown-item box-spacing">
      <div class="input-wrap">
        <input type="text" class="text" value="USDT" />
        <img
          src="@/assets/images/Tether (USDT).svg"
          class="input-icon"
          alt="USDT"
        />
      </div>
      <input type="text" class="text" value="18 Decimals" />
    </li>
    <li class="dropdown-item">
      <div class="dropdown drop-content">
        <div class="wrap-box">
          <img src="@/assets/images/Tether (USDT).svg" class="" alt="USDT" />
          <span class="text">USDT</span>
        </div>
        <span class="text-normal">0.00</span>
      </div>
    </li>
    <div  class="button-wrapper box-spacing">
      <button  class="button-primary">
        Add
      </button>
      <button  class="button cancel-button text" @click="closeDropdown">
        Cancel
      </button>
    </div>
    </div>
  </ul>
</template>
<script setup>
import {ref} from 'vue'

const showItems = ref(false);

function checkClicked() {
  showItems.value = true;
}

function closeDropdown() {  
  showItems.value = false;
}
</script>
<style scoped>
.dropdown-menu {
  padding: 12px;
}
.dropdown-item.search {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;
}
.drop-wrap .box-spacing {
  padding: 0;
}
.input-wrap {
  width: 100%;
}
.drop-content {
  justify-content: space-between;
}
.text{
  color: var(--dashboard-neutral-950);
}
.cancel-button.text{
  color: var(--dashboard-neutral-500);
}
</style>