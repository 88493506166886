<template>
  <aside class="sidebar">
    <div class="wrapper">
      <MainWallet v-if="isMobile" />
      <div class="flex-container desk-navigation">
        <img src="@\assets\images\logo.svg" alt="Logo" />
        <WalletDropdown />


      </div>

      <div class="content-wrapper desk-nav">
        <router-link to="/" class="button" :class="{ active: activeButton === 'HouseSimple' }"
          @click="setActive('HouseSimple')">
          <img src="@/assets/images/HouseSimple.svg" alt="" v-if="activeButton === 'HouseSimple'" />
          <img src="@/assets/images/HouseSimpleD.svg" alt="" v-else />

        </router-link>
        <router-link to="/protected-assets" class="button" :class="{ active: activeButton === 'Shield' }"
          @click="setActive('Shield')">
          <img src="@/assets/images/ShieldFill.svg" alt="" v-if="activeButton === 'Shield'" />
          <img src="@/assets/images/Shield.svg" alt="" v-else />
        </router-link>
        <router-link to="/logs" class="button" :class="{ active: activeButton === 'Notepad' }"
          @click="setActive('Notepad')">
          <img src="@/assets/images/NotepadFill.svg" alt="" v-if="activeButton === 'Notepad'" />
          <img src="@/assets/images/Notepad.svg" alt="" v-else />
        </router-link>
        <router-link to="/settings" class="button" :class="{ active: activeButton === 'Gear' }"
          @click="setActive('Gear')">
          <img src="@/assets/images/GearFill.svg" alt="" v-if="activeButton === 'Gear'" />
          <img src="@/assets/images/Gear.svg" alt="" v-else />
        </router-link>
      </div>
      <!-- hiç bir asset olmadığında göstereceğimiz div -->
      <div class="assets-info">
        <p class="text">You don’t have any protected assets</p>
        <router-link to="/protectstep" class="button-primary">
          <img src="@\assets\images\ShieldW.svg" alt="" />
          Protect Your Assets Now
        </router-link>
      </div>
      <!-- <div class="wrap-box coin-wrap">
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
       <QuickAction/>
      </div> -->

      <div class="assets-box">
        <div class="flex-container">
          <div class="box-spacing">
            <h6 class="title-s">Wallet Assets</h6>
            <span class="text-second badge-m">2</span>
          </div>
          <div class="wrap-box">
            <AddCustomToken />
            <SortItem />
            <button @click="toggleShowBox" class="show-btn"><img src="@\assets\images\CaretDown.svg"
                :class="boxRef && 'rotate-180'" alt="CaretDown" v-show="isMobile"></button>
          </div>
        </div>
        <div class="input-wrap" v-if="isMobile ? boxRef : true">
          <input class="text" type="text" placeholder="USD" />
          <img src="@\assets\images\MagnifyingGlass.svg" class="input-icon" alt="Magnifying" />
        </div>
        <div class="scrollable" v-if="isMobile ? boxRef : true">
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />
          <WalletAsset />

        </div>
      </div>
      <div class="line"></div>
    </div>
  </aside>
</template>
<script setup>
import { ref, computed } from "vue";
import WalletAsset from '@/components/WalletAsset.vue';
// import QuickAction from '@/components/QuickAction';
import { useScreenWidth } from '@/helpers/useScreenWidth';
import SortItem from '@/components/SortItem.vue';
import AddCustomToken from '@/components/AddCustomToken.vue';
import WalletDropdown from '@/components/WalletDropdown.vue';
import MainWallet from '@/components/MainWallet.vue';


const screenWidth = useScreenWidth();
const MOBILE_BREAKPOINT = 991;
const isMobile = computed(() => screenWidth.value <= MOBILE_BREAKPOINT);

const activeButton = ref("HouseSimple");
const boxRef = ref(true)

const toggleShowBox = () => {
  boxRef.value = !boxRef.value
}
const setActive = (buttonName) => {
  activeButton.value = buttonName;
};


</script>
<style scoped>
.input-wrap {
  width: 100%;
}

.show-btn img {
  width: 20px;
  height: 20px;
}




.coin-wrap.wrap-box {
  padding: 16px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--dashboard-neutral-100);
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  flex-shrink: 0;

}

.coin-wrap.wrap-box::-webkit-scrollbar {
  height: 3px;
}
</style>