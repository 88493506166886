<template>
    <footer class="footer flex-container">
        <div class="box-spacing">
            <img src="@\assets\images\logo.svg" alt="Logo">
            <p class="title-xs">&copy; 2024 CryptoLocks. All Rights Reserved</p>
        </div>
        <div class="social-media content-wrapper">
            <button class="button"><img src="@\assets\images\Facebook.svg" alt="Facebook"></button>
            <button class="button"><img src="@\assets\images\Twitter.svg" alt="Twitter"></button>
            <button class="button"><img src="@\assets\images\Medium.svg" alt="Medium"></button>
            <button class="button"><img src="@\assets\images\Linkedin.svg" alt="Linkedin"></button>

        </div>
        
    </footer>
</template>
<style scoped>
.flex-container{
    grid-column: 1 / -1;
    padding-bottom: 30px;
}
.title-xs{
    color: var(--dashboard-neutral-500);
}
.box-spacing{
    gap: 20px;
}



</style>