import SettingsView from "@/views/Settings/SettingsView.vue";

export const SettingsRoutes = [
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
  },
 
];
